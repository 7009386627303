import { Box, Typography } from '@mui/material';
// import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const Pagetitle = ({ title, children, pageCSS = {}, subtitle = '' }) => {
  return (
    <Box sx={{ maxWidth: 'fit-content' }}>
      <Typography
        variant="h4"
        sx={{
          mb: !subtitle ? 3 : 0,
          display: 'flex',
          fontWeight: '600',
          ...pageCSS,
        }}
      >
        {title}
      </Typography>
      {subtitle && (
        <Typography
          variant="caption"
          sx={{ mb: 5, fontSize: '14px', display: 'flex' }}
        >
          {subtitle}
        </Typography>
      )}
      {/* <Typography
        sx={{
          mb: 3,
          display: 'flex',
          alignSelf: 'center',
          marginLeft: '10px',
          gap: '4px',
          cursor: 'pointer',
          backgroundColor: 'rgb(227, 227, 227)',
          padding: '2px 10px 2px 10px',
          fontSize: '14px !important',
          fontWeight: '500',
          borderRadius: '5px',
          alignItems: 'center',
        }}
      >
        <InfoOutlinedIcon color="inherit" sx={{ fontSize: '14px' }} />
        About
      </Typography> */}
      {children}
    </Box>
  );
};

export default Pagetitle;

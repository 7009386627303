import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
// utils
import axios from '@/utils/axios';
import { setSession, getSessionWithKey } from '@/utils/shopify';
import { trackIdentity } from '@/utils/juneUtil';

// ----------------------------------------------------------------------

const initialState = {
  isDemoAccount: false,
  isAuthenticated: false,
  isInitialized: false,
  isFetchedStoreInfo: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isDemoAccount, isAuthenticated, user } = action.payload;
    return {
      ...state,
      isDemoAccount,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN_WITH_DEMO: (state, action) => {
    return {
      ...state,
      isDemoAccount: true,
      isAuthenticated: true,
      user: action.payload,
    };
  },
  LOGIN: (state, action) => {
    return {
      ...state,
      isDemoAccount: false,
      isAuthenticated: true,
      user: action.payload,
    };
  },
  FETCHED_STORE_INFO: (state, action) => {
    return {
      ...state,
      isFetchedStoreInfo: true,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isDemoAccount: false,
    isAuthenticated: false,
    user: null,
  }),
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  method: 'shopifyAuth',
  fetchStoreInfo: () => Promise.resolve(),
  loginWithDemo: () => Promise.resolve(),
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  consent: () => Promise.resolve(),
  forceLogin: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        let shopifyAuth = JSON.parse(
          window.localStorage.getItem('shopifyAuth')
        );

        if (!shopifyAuth) {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isDemoAccount: false,
              isAuthenticated: false,
              user: null,
            },
          });
          return;
        }

        setSession(shopifyAuth);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isDemoAccount: shopifyAuth.isDemoAccount,
            isAuthenticated: true,
            user: shopifyAuth,
          },
        });
      } catch (err) {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isDemoAccount: false,
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const fetchStoreInfo = async (store_id) => {
    const secret = getSessionWithKey('secret');
    const internalUser = getSessionWithKey('internalUser');
    const response = await axios.post('/fetch_storeinfo', {
      store_id,
      secret,
    });

    const data = {
      isAuthenticated: true,
      isDemoAccount: false,
      store_id,
      active: response?.data?.active,
      logo: response?.data?.logo,
      name: response?.data?.name,
      has_consented: response?.data?.has_consented,
      email: response?.data?.email,
      subscribed: response?.data?.subscribed,
      subscription_plan_id: response?.data?.subscription_plan_id,
      subscription_plan_name: response?.data?.subscription_plan_name,
      is_shopify_plus: response?.data?.is_shopify_plus,
      secret,
      iana_timezone: response?.data?.iana_timezone,
      currency_code: response?.data?.currency_code,
      gsheet_url: response?.data?.gsheet_url,
      show_free_trial_expired_message:
        response?.data?.show_free_trial_expired_message,
      internalUser: internalUser,
    };

    setSession(data);
    dispatch({
      type: 'LOGIN',
      payload: data,
    });

    dispatch({
      type: 'FETCHED_STORE_INFO',
    });
  };

  const login = async (code, shop, hmac, host, timestamp) => {
    const response = await axios.post('/auth/store/token', {
      code,
      shop,
      hmac,
      host,
      timestamp,
    });

    const data = {
      isAuthenticated: true,
      isDemoAccount: false,
      store_id: response?.data?.id,
      active: response?.data?.active,
      logo: response?.data?.logo,
      name: response?.data?.name,
      has_consented: response?.data?.has_consented,
      email: response?.data?.email,
      secret: response?.data?.secret,
      subscribed: response?.data.subscribed,
      subscription_plan_id: response?.data?.subscription_plan_id,
      is_shopify_plus: response?.data?.is_shopify_plus,
      gsheet_url: response?.data?.gsheet_url,
      show_free_trial_expired_message:
        response?.data?.show_free_trial_expired_message,
      iana_timezone: response?.data?.iana_timezone,
      currency_code: response?.data?.currency_code,
    };

    if (response?.data?.id) {
      trackIdentity({
        store_id: response.data.id,
        email: response.data.email,
        name: response.data.name,
        avatar: response.data.logo,
      });
    }

    setSession(data);
    dispatch({
      type: 'LOGIN',
      payload: data,
    });

    fetchStoreInfo(response?.data?.id);
  };

  const consent = async (store_id, email) => {
    const secret = getSessionWithKey('secret');
    const response = await axios.post('/fetch_storeinfo', {
      store_id,
      email,
      has_consented: true,
      secret,
    });

    const data = {
      isAuthenticated: true,
      isDemoAccount: false,
      store_id,
      active: response?.data?.active,
      logo: response?.data?.logo,
      name: response?.data?.name,
      has_consented: response?.data?.has_consented,
      email: response?.data?.email,
      subscribed: response?.data.subscribed,
      subscription_plan_id: response?.data?.subscription_plan_id,
      is_shopify_plus: response?.data?.is_shopify_plus,
      gsheet_url: response?.data?.gsheet_url,
      show_free_trial_expired_message:
        response?.data?.show_free_trial_expired_message,
      secret,
      iana_timezone: response?.data?.iana_timezone,
      currency_code: response?.data?.currency_code,
    };

    setSession(data);
    dispatch({
      type: 'LOGIN',
      payload: data,
    });
    dispatch({
      type: 'FETCHED_STORE_INFO',
    });
  };

  const loginWithDemo = () => {
    const data = {
      store_id: process.env.REACT_APP_DEMO_STORE_ID,
      name: 'Demo Store',
      logo: null,
      isAuthenticated: true,
      isDemoAccount: true,
      secret: process.env.REACT_APP_DEMO_SECRET_KEY,
    };

    setSession(data);
    dispatch({
      type: 'LOGIN_WITH_DEMO',
      payload: data,
    });
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  const forceLogin = async ({ store_id, secret }) => {
    const session = localStorage.getItem('shopifyAuth') || '{}'; // remove '{}' if the user must be logged in before a force login
    if (!session) {
      return;
    }
    let sessionData = JSON.parse(session);
    sessionData.secret = secret;
    sessionData.store_id = store_id;
    sessionData.internalUser = true;
    setSession(sessionData);
    dispatch({
      type: 'LOGIN',
      payload: sessionData,
    });
    fetchStoreInfo(store_id);
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'shopifyAuth',
        fetchStoreInfo,
        loginWithDemo,
        login,
        logout,
        consent,
        forceLogin,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from '@/redux/store';
// @mui
import Box from '@mui/material/Box';
import {
  Alert,
  Badge,
  Button,
  Container,
  Link,
  List,
  ListItem,
  Paper,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
// components
import Page from '@/components/Page';
import SpinnerV2 from '@/components/SpinnerV2';
import useAuth from '@/hooks/useAuth';
import Pagetitle from '@/sections/@dashboard/PageTitle';
import {
  cancelSubscription,
  createMonthlySubscription,
} from '@/redux/slices/subscription';
import CheckIcon from '@mui/icons-material/Check';
import { getSessionWithKey } from '@/utils/shopify';

// ----------------------------------------------------------------------

const BoxCodeStyle = styled(Box)(({ theme }) => ({
  // ...
}));

const PRICING_PLANS = [
  {
    plan_id: 2,
    plan_name: 'Kendall Light',
    price: '99',
    features: ['Includes all features'],
    recommended_for: 'Up to $1M yearly GMV',
  },
  {
    plan_id: 3,
    plan_name: 'Kendall Growth',
    price: '199',
    features: ['Includes all features', 'Standard support'],
    recommended_for: 'Up to $5M yearly GMV',
  },
  {
    plan_id: 4,
    plan_name: 'Kendall Pro',
    price: '349',
    features: ['Includes all features', 'Priority support'],
    recommended_for: 'Up to $10M yearly GMV',
  },
  {
    plan_id: 5,
    plan_name: 'Kendall Unlimited',
    price: '499',
    features: ['Includes all features', 'Priority support'],
    recommended_for: 'Unlimited GMV',
  },
  {
    plan_id: 7,
    plan_name: 'Kendall Max',
    price: '999+',
    features: [
      'Includes all features',
      'Priority support',
      'Monthly consulting sessions',
      'Custom reports',
    ],
    join_waitlist: true,
  },
];

const Subscriptions = () => {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const { return_url, isLoading } = useSelector((state) => state.subscription);

  useEffect(() => {
    if (return_url) {
      window.location.href = return_url;
      return;
    }
  }, [return_url]);

  const handleSelectPlan = (plan) => {
    const plan_id = plan.plan_id;

    if (plan.join_waitlist) {
      // bring up the email app, using mail:to
      document.location = `mailto:team@kendall.ai?subject=Join the Waitlist for Kendall Custom&body=Please tell us your store name, GMV, and what you're looking for in terms of consulting help `;
      return;
    }

    // if (plan.price === 0) {
    //   console.log('secret', getSessionWithKey('secret'));
    //   dispatch(
    //     cancelSubscription({
    //       store_id: user.store_id,
    //       secret: getSessionWithKey('secret'),
    //     })
    //   );
    //   return;
    // }

    // logic to select a plan
    dispatch(
      createMonthlySubscription({ store_id: user.store_id, plan_id: plan_id })
    );
  };

  const userHasSubscribed =
    user?.subscribed === 'true' || user?.subscribed === true;

  const showPlanGrandfatheredBanner =
    userHasSubscribed && user?.subscription_plan_id === 1 ? true : false;
  const isShopifyPlus = user?.is_shopify_plus === 'true';
  const trialDaysRemaining = user?.trial_days_remaining || 0;
  const trialDaysText =
    trialDaysRemaining > 0
      ? `${trialDaysRemaining} Days left on your trial`
      : trialDaysRemaining === 0
      ? null
      : '30-days trial included';

  return (
    <Page title="Choose a plan">
      <BoxCodeStyle>
        <Container maxWidth={'lg'} className="page-container-class">
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: '10px',
              borderRadius: '16px',
            }}
          >
            <Pagetitle title={`Choose a subcription plan`} />
          </Box>
          {showPlanGrandfatheredBanner && (
            <Box sx={{ mb: 3 }}>
              <Alert severity="info">
                As one of our early customers, you are on the Kendall Unlimited
                plan at a grandfathered price of $95/mo! To keep this price, do
                not choose a new plan below.
              </Alert>
            </Box>
          )}

          <Box sx={{ mb: 3 }}>
            <Typography sx={{ color: '#black', fontWeight: 'semibold' }}>
              We believe in fair pricing. All plans include all features.
              <br />
              Don't know your store's yearly GMV (total sales)? No problem. Just
              make your best guess and we'll adjust it later.
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(4, 1fr)',
              gap: '20px',
            }}
          >
            {PRICING_PLANS.map((plan) => (
              <Paper
                variant={'outlined'}
                key={plan.plan_id}
                sx={{ mb: 4, p: '20px', minHeight: '390px' }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 'bold',
                    textAlign: 'center',
                    fontSize: '1rem',
                  }}
                >
                  {plan.plan_name}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'row',
                    gap: '2px',
                    marginTop: '10px',
                  }}
                >
                  <Typography variant="h4">${plan.price}</Typography>
                  <Typography
                    variant="caption"
                    sx={{ color: '#807979', fontWeight: 'bold' }}
                  >
                    /month
                  </Typography>
                </Box>
                {plan.recommended_for && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'row',
                      gap: '2px',
                      marginTop: '10px',
                    }}
                  >
                    <Typography
                      variant="caption"
                      sx={{
                        color: '#807979',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        margin: '0 auto',
                        width: '100%',
                      }}
                    >
                      {plan.recommended_for}
                    </Typography>
                  </Box>
                )}
                {/* Add a badge "30 day free trial" for trial days */}
                {!user?.subscription_plan_id && (
                  <Badge
                    variant="standard"
                    sx={{
                      ml: 2,
                      color: '#406b97',
                      backgroundColor: '#cde3f8',
                      borderRadius: '6px',
                      marginTop: '16px',
                      fontWeight: 700,
                      textAlign: 'center',
                      fontSize: '0.75rem',
                      display: 'flex',
                      width: 'fit-content',
                      padding: '2px 8px 2px 8px',
                      margin: '1rem auto -1rem auto',
                    }}
                  >
                    30 day free trial
                  </Badge>
                )}
                <Box sx={{ textAlign: 'center', marginTop: '2rem' }}>
                  {trialDaysText && (
                    <Typography
                      variant="caption"
                      sx={{
                        background: '#cde2f8',
                        padding: '4px 8px 4px 8px',
                        color: '#15489a',
                        borderRadius: '6px',
                        marginTop: '16px',
                        display: 'block',
                        fontWeight: 600,
                      }}
                    >
                      {trialDaysText}
                    </Typography>
                  )}
                </Box>

                <Box sx={{ mt: 1 }}>
                  <Button
                    variant={'contained'}
                    fullWidth
                    size={'small'}
                    onClick={() => handleSelectPlan(plan)}
                    disabled={
                      isLoading || user?.subscription_plan_id === plan.plan_id
                    }
                    loading={isLoading}
                    sx={{
                      background: 'rgb(48, 48, 48, 0.95)',
                      '&:hover': {
                        background: 'rgb(26, 26, 26)',
                      },
                    }}
                  >
                    {user?.subscription_plan_id === plan.plan_id
                      ? 'Current Plan'
                      : plan.join_waitlist
                      ? 'Join Waitlist'
                      : 'Choose'}
                  </Button>
                </Box>

                <Box sx={{ mt: 6 }}>
                  <List
                    style={{
                      listStyle: 'none',
                      padding: 0,
                      margin: 0,
                      fontSize: '12px',
                    }}
                  >
                    {plan.features.map((feature) => (
                      <ListItem key={feature}>
                        <CheckIcon
                          sx={{ fontSize: '16px', marginRight: '5px' }}
                        />
                        <Typography sx={{ fontSize: '14px' }} variant="caption">
                          {feature}
                        </Typography>
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Paper>
            ))}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Typography
                variant="body1"
                sx={{
                  color: 'text.primary',
                  fontWeight: 'bold',
                  textDecoration: 'underline',
                  alignSelf: 'center',
                  transform: 'translateY(-32px)',
                }}
              >
                {/* {!userHasSubscribed && (
                  <Link href={'/dashboard/integrations'}>Skip, for now</Link>
                )} */}
                {/* {userHasSubscribed && (
                  <Link href={'/dashboard'}>Keep my plan the same</Link>
                )} */}
              </Typography>
            </Box>
          </Box>
        </Container>
      </BoxCodeStyle>
      {isLoading && <SpinnerV2 />}
    </Page>
  );
};

export default Subscriptions;

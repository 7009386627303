// routes
import { PATH_DASHBOARD } from './routes/paths';

// API
// ----------------------------------------------------------------------

export const HOST_API = process.env.REACT_APP_HOST_API_KEY || '';

export const FIREBASE_API = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const COGNITO_API = {
  userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
};

export const AUTH0_API = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export const MAPBOX_API = process.env.REACT_APP_MAPBOX;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.main.liveOrders; // as '/dashboard/app'
export const PATH_AFTER_CONSENT = PATH_DASHBOARD.main.checklist;
export const SUBSCRIPTIONS_PAGE_PATH = PATH_DASHBOARD.main.subscriptions;
export const SETTINGS_PAGE_PATHS = [
  PATH_DASHBOARD.main.checklist,
  PATH_DASHBOARD.main.integrations,
  PATH_DASHBOARD.main.utmBuilder,
  PATH_DASHBOARD.main.utmValidator,
  PATH_DASHBOARD.main.subscriptions,
];

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 50,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 170,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 32,
  DASHBOARD_ITEM_SUB_HEIGHT: 30,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 27,
};

export const ICON = {
  NAVBAR_ITEM: 16,
  NAVBAR_ITEM_HORIZONTAL: 16,
};

// SETTINGS
// Please remove `localStorage` when you set settings.
// ----------------------------------------------------------------------

export const defaultSettings = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeColorPresets: 'default',
  themeLayout: 'horizontal',
  themeStretch: false,
};

export const BACKEND_URL =
  process.env.REACT_APP_BACKEND_URL || 'http://localhost:3007';

export const PER_PAGE = 10;

export const ROW_PER_PAGE_OPTIONS = [10, 20, 50, 100, 200, 500, 1000];

export const NOTIFICATION_TIME_OUT = 5000;

import React, { useEffect } from 'react';
// hooks
import useAuth from '@/hooks/useAuth';
import { dispatch, useSelector } from '@/redux/store';
import {
  createMonthlySubscription,
  // getActiveSubscription,
  // subscriptionCallback,
} from '@/redux/slices/subscription';
import SubscriptionNotice from './SubscriptionNotice';

const SubscriptionCheck = () => {
  const { isAuthenticated, isDemoAccount, user, isFetchedStoreInfo } =
    useAuth();
  // eslint-disable-next-line no-unused-vars
  const { return_url } = useSelector((state) => state.subscription);

  useEffect(() => {
    if (return_url) {
      console.log({ return_url });
      window.location.href = return_url;
      return;
    }
  }, [return_url]);

  // create monthly subscription
  // useEffect(() => {
  //   if (
  //     !isDemoAccount &&
  //     user &&
  //     isFetchedStoreInfo &&
  //     user.subscribed !== 'true'
  //   ) {
  //     // console.log({ user });
  //     // user has not subscribed yet, get a subscription link
  //     console.log('user has not subscribed yet, get a subscription link');
  //     dispatch(createMonthlySubscription({ store_id: user.store_id }));
  //   }
  // }, [user, isFetchedStoreInfo, isDemoAccount]);

  if (
    isFetchedStoreInfo &&
    (user?.show_free_trial_expired_message === 'true' ||
      user?.show_free_trial_expired_message === true)
  ) {
    return <SubscriptionNotice open={true} />;
  }

  return <div></div>;
};

export default SubscriptionCheck;
